import { mapGetters } from 'vuex';
import * as reflib from "reflib";
import { Stream } from "../classes.js";
import serverMixin from './serverMixin';
import filenameMixin from './filenameMixin';

export default {
	mixins: [
		serverMixin,
		filenameMixin
	],
	data() {
		return {
			isLoading: false,
			progress: 0
		}
	},
	computed: {
		...mapGetters({
			refs: 'duplicates/getRefs'
		})
	},
	methods: {
		// Convert array into chunks of size
		chunkArray(array, size) {
			let result = []
			for (let i = 0; i < array.length; i += size) {
					let chunk = array.slice(i, i + size)
					result.push(chunk)
			}
			return result
		},
		// Process array synchronously (like promise.all)
		processArray(array, fn) {
			var results = [];
			return array.reduce((prevItem, item, index) => {
					return prevItem.then(() => {
							// Set progress as percentage
							this.progress = (index / array.length) * 100;
							return fn(item).then(data => {
									results.push(data);
									return results;
							});
					});
			}, Promise.resolve());
		},
		// Fetch references synchronously from lens then download xml
		fetchThenDownload(refIds, name, format) {
			this.isLoading = true;
			// Split into chunks and get refs
			console.log("Loading...")
			refIds = this.chunkArray(refIds, 1000);
			// Synchronously get refs from lens
			this.processArray(refIds, this.getReferences).then(refs => {
				// all done
				refs = refs.flat();
				this.callDownload(refs, name, format);
				this.isLoading = false;
				this.progress = 0;
			}, err => {
				// rejection happened
				alert(err);
				console.error(err);
				this.isLoading = false;
				this.progress = 0;
			});
		},
		// Call download xml
		callDownload(refs, name, format) {
			// Download
			this.downloadFile(
				refs,
				this.getProjectName()
					+ "_"
					+ name
					+ " "
					+ this.getDateString()
					+ format.extention,
				format.driver
			);
		},
		downloadFile(content, filename, driver) {
			// Generate xml file
			const stream = new Stream();
			reflib
				.output({
					format: driver,
					stream: stream,
					content: content
				})
				.on("finish", () => {
					const pom = document.createElement("a");
					const bb = new Blob([stream.getData()], { type: "text/plain" });

					pom.setAttribute("href", window.URL.createObjectURL(bb));
					pom.setAttribute("download", filename);

					pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
						":"
					);
					pom.draggable = true;
					pom.classList.add("dragout");

					pom.click();
				});
		}
	}
}