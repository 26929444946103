<template>
	<div>
		<b-card header-bg-variant="dark" header-text-variant="white">
			<template #header>
				Reference Details
				<b-button class="float-right" @click="$root.$emit('hide-edit');"><b-icon-x /></b-button>
			</template>

			<b>{{ this.formatCitation(reference) }}</b>

			<legend>Reference Details</legend>
			<!-- Title -->
			<b-form-group label="Title:" label-cols="1">
				<b-form-input disabled v-model="reference.title"></b-form-input>
			</b-form-group>
			<!-- Authors -->
			<b-form-group label="Authors:" label-cols="1">
				<b-form-tags disabled v-model="reference.authors"></b-form-tags>
			</b-form-group>

			<legend>Web Meta Details</legend>
			<!-- Keywords -->
			<b-form-group label="Keywords:" label-cols="1">
				<b-form-tags disabled v-model="reference.keywords"></b-form-tags>
			</b-form-group>
			<!-- DOI -->
			<b-form-group label="DOI:" label-cols="1">
				<b-form-input disabled v-model="reference.doi"></b-form-input>
			</b-form-group>
			<!-- URLs -->
			<b-form-group label="URLs:" label-cols="1">
				<b-form-tags v-model="reference.urls"></b-form-tags>
			</b-form-group>

			<legend>Textual Attatchments</legend>
			<!-- Abstract -->
			<b-form-group label="Abstract:" label-cols="1">
				<b-form-textarea disabled rows="4" v-model="reference.abstract"></b-form-textarea>
			</b-form-group>
			<!-- Notes -->
			<b-form-group label="Notes:" label-cols="1">
				<b-form-textarea rows="4" v-model="reference.notes"></b-form-textarea>
			</b-form-group>
			<!-- Research Notes -->
			<b-form-group label="Research Notes:" label-cols="1">
				<b-form-textarea rows="4" v-model="reference.researchNotes"></b-form-textarea>
			</b-form-group>

			<legend>Journal Information</legend>
			<!-- Journal -->
			<b-form-group label="Journal:" label-cols="1">
				<b-form-input disabled v-model="reference.journal"></b-form-input>
			</b-form-group>
			<!-- Year -->
			<b-form-group label="Year:" label-cols="1">
				<b-form-input disabled v-model="reference.year"></b-form-input>
			</b-form-group>
			<!-- Date -->
			<b-form-group label="Date:" label-cols="1">
				<b-form-input disabled v-model="reference.date"></b-form-input>
			</b-form-group>
			<!-- Pages -->
			<b-form-group label="Pages:" label-cols="1">
				<b-form-input disabled v-model="reference.pages"></b-form-input>
			</b-form-group>
			<!-- Volume -->
			<b-form-group label="Volume:" label-cols="1">
				<b-form-input disabled v-model="reference.volume"></b-form-input>
			</b-form-group>
			<!-- Number -->
			<b-form-group label="Number:" label-cols="1">
				<b-form-input disabled v-model="reference.number"></b-form-input>
			</b-form-group>
			<!-- ISBN -->
			<b-form-group label="ISBN:" label-cols="1">
				<b-form-input disabled v-model="reference.isbn"></b-form-input>
			</b-form-group>
			<!-- Label -->
			<b-form-group label="Label:" label-cols="1">
				<b-form-input v-model="reference.label"></b-form-input>
			</b-form-group>
			<!-- Caption -->
			<b-form-group label="Caption:" label-cols="1">
				<b-form-input v-model="reference.caption"></b-form-input>
			</b-form-group>
			<!-- Address -->
			<b-form-group label="Address:" label-cols="1">
				<b-form-input disabled v-model="reference.address"></b-form-input>
			</b-form-group>
		</b-card>
	</div>
</template>

<script>
import { debounce } from "debounce";

import formatMixin from "../mixins/formatMixin"

export default {
	props: {
		reference: Object
	},
	mixins: [formatMixin],
	methods: {
		saveReference: debounce(function(newVal) {
			this.$store.commit('references/updateRef', newVal);
		}, 100)
	},
	watch: {
		reference: {
			handler(newVal) {
				this.saveReference(newVal);
			},
			deep: true
		}
	}
}
</script>

<style scoped>
.card-header {
	position: sticky;
	background: #ececec;
	z-index: 101;
	top: 0;
}
</style>